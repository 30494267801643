<template>
    <section class="causes">
        <div class="container py-5">
            <h1 class="text-center font-weight-bold title mt-2 mb-5"
                data-aos="fade-up"
                data-aos-duration="1000">
                鬆弛尿滲成因
            </h1>

            <div class="row">
                <div class="col-6 col-lg-3 text-center mb-3">
                    <img data-aos="flip-up"
                         data-aos-duration="1000"
                         alt="Intima"
                         class="icon img-fluid mb-4"
                         src="../../../assets/campaign/intima/causes/pregnant.svg">

                    <h3 class="d-none d-lg-block"
                        data-aos="fade"
                        data-aos-duration="1000"
                        data-aos-delay="500">
                        1. 懷孕生育
                    </h3>
                    <h4 class="d-block d-lg-none"
                        data-aos="fade"
                        data-aos-duration="1000"
                        data-aos-delay="500">
                        1. 懷孕生育
                    </h4>
                </div>

                <div class="col-6 col-lg-3 text-center mb-3">
                    <img data-aos="flip-up"
                         data-aos-duration="1000"
                         alt="Intima"
                         class="icon img-fluid mb-4"
                         src="../../../assets/campaign/intima/causes/sex.svg">

                    <h3 class="d-none d-lg-block"
                        data-aos="fade"
                        data-aos-duration="1000"
                        data-aos-delay="500">
                        2. 性生活頻密
                    </h3>
                    <h4 class="d-block d-lg-none"
                        data-aos="fade"
                        data-aos-duration="1000"
                        data-aos-delay="500">
                        2. 性生活頻密
                    </h4>
                </div>

                <div class="col-6 col-lg-3 text-center mb-3">
                    <img data-aos="flip-up"
                         data-aos-duration="1000"
                         alt="Intima"
                         class="icon img-fluid mb-4"
                         src="../../../assets/campaign/intima/causes/aging.svg">

                    <h3 class="d-none d-lg-block"
                        data-aos="fade"
                        data-aos-duration="1000"
                        data-aos-delay="500">
                        3. 年齡增長 <span class="d-block">膠原流失</span>
                    </h3>
                    <h4 class="d-block d-lg-none"
                        data-aos="fade"
                        data-aos-duration="1000"
                        data-aos-delay="500">
                        3. 年齡增長 <span class="d-block">膠原流失</span>
                    </h4>
                </div>

                <div class="col-6 col-lg-3 text-center mb-3">
                    <img data-aos="flip-up"
                         data-aos-duration="1000"
                         alt="Intima"
                         class="icon img-fluid mb-4"
                         src="../../../assets/campaign/intima/causes/fat.svg">

                    <h3 class="d-none d-lg-block"
                        data-aos="fade"
                        data-aos-duration="1000"
                        data-aos-delay="500">
                        4. 肥胖
                    </h3>
                    <h4 class="d-block d-lg-none"
                        data-aos="fade"
                        data-aos-duration="1000"
                        data-aos-delay="500">
                        4. 肥胖
                    </h4>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    export default {
        name: "IntimaCauses"
    }
</script>

<style lang="scss" scoped>
    @import "~bootstrap/scss/functions";
    @import "~bootstrap/scss/variables";
    @import "~bootstrap/scss/mixins/_breakpoints";
    @import "./variables.scss";

    .causes {
        background-image: url($background-alt);
        background-color: $background-color-alt;
        color: $text-color;

        .icon {
            max-width: 150px;
            width: 100%;
        }
    }
</style>
